import React from "react"
import { Link } from "gatsby"
import { SectionTitle, SectionTitle2 } from "../components/styled"
import WrapperRight from "../components/WrapperRight"
import JobContent from "../components/JobContent"
import SearchJob from "../components/SearchJob"
import { FaAngleRight } from "react-icons/fa"
import scrollTo from "gatsby-plugin-smoothscroll"
import useBestJobs from "../hooks/useBestJobs"
import { Seo } from "../components/seo"
import useJobs from "../hooks/useJobs"
import Contact from "../components/Contact"
const JobsListing = () => {
  const dataJobs = useJobs("Japanese")
  const dataBestJobs = useBestJobs()
  return (
    <>
      <section className="container jobs-listing">
        <div className="row">
          <div className="col-lg-9">
            <div className="jobs-listing__search">
              <SectionTitle>メキシコ求人・就職・転職情報</SectionTitle>
              <hr className="u-line-bottom" />
              <div className="jobs-listing__search--box">
                <SearchJob lang="ja" />
              </div>
            </div>
            <div className="jobs-listing__recommended-jobs">
              <div className="jobs-listing__recommended-jobs--title-link">
                <SectionTitle2 style={{ margin: "0" }}>
                  今週のオススメ求人
                </SectionTitle2>
                <Link to="/category/オススメのメキシコ求人">
                  <FaAngleRight />
                  これまでのオススメ求人を見る
                </Link>
              </div>
              <div className="jobs-listing__recommended-jobs--top-5">
                <p>{dataBestJobs[0].title}</p>
                <ul>
                  {dataBestJobs[0].topjobs.map((job, i) => (
                    <li key={i}>
                      <Link
                        to={`/osusume${job.Link}#matome0${i + 1}`}
                        onClick={() => scrollTo(`#matome0${i + 1}`)}
                        rel="noreferrer"
                      >
                        {job.Title}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="jobs-listing__job-info">
              <SectionTitle2>求人情報</SectionTitle2>
              <hr className="u-line-bottom" />
              {dataJobs.map((jobs, i) => (
                <React.Fragment key={"jobContent_" + i}>
                  <JobContent jobs={jobs} lang={"ja"} />
                </React.Fragment>
              ))}
              <Contact lang={"ja"} />
            </div>
          </div>
          <WrapperRight />
        </div>
      </section>
    </>
  )
}

export default JobsListing

export const Head = ({ data }) => {
  const SEO = data?.allStrapiJobs?.nodes[0]
  return (
    <Seo
      title={`メキシコ求人・就職・転職情報`}
      description={SEO?.JobDescription}
      image={SEO?.types[0]?.image?.url}
      url={`https://www.919mexico.com/jobs-listing`}
    />
  )
}
